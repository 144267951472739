import { Flex, Text } from '@chakra-ui/react'
import { useOrgID, useUserID } from '../hooks/id_token_claims'
import { useLoadFreeTrialEndedQuery } from '../generated/graphql'
import LogoutButton from './logout_button'
import { DOPPEL_DARK_SECONDARY } from '../utils/style'

const FreeTrialEnded: React.FC = () => {
  const orgId = useOrgID()
  const [userID] = useUserID()
  const { data } = useLoadFreeTrialEndedQuery({
    variables: {
      organization_id: orgId,
      user_id: userID,
    },
  })

  return (
    <Flex align="center" h="100vh" justify="center" w="100vw">
      <Flex
        backgroundColor={DOPPEL_DARK_SECONDARY}
        direction="column"
        padding="24px"
        w="500px"
      >
        <Text mb="20px">
          Hi {data?.users_by_pk?.name}! Unfortunately your trial has expired 😞 Please
          reach out to support@doppel.com to see how {data?.organizations_by_pk?.name}{' '}
          can move to a production version of Doppel Vision.
        </Text>

        <Flex justify="flex-end">
          <LogoutButton buttonSizeState={'normal'} justify="flex-end" />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FreeTrialEnded
