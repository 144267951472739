import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@chakra-ui/react'
import { DOPPEL_TERMINAL_BLUE, DOPPEL_WHITE } from '../utils/style'

const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0()

  return (
    <Button
      bgColor={DOPPEL_WHITE}
      color={DOPPEL_TERMINAL_BLUE}
      mr={4}
      onClick={() => {
        loginWithRedirect({
          appState: {
            auth0RedirectPath: window.location.href.slice(
              window.location.origin.length,
            ),
          },
        })
      }}
      size={'lg'}
      variant={'solid'}
    >
      Log In
    </Button>
  )
}

export default LoginButton
