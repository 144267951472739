import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  Text,
  Spacer,
  useClipboard,
} from '@chakra-ui/react'
import { FaBug, FaCheck, FaEye, FaLock } from 'react-icons/fa'
import {
  getOrgData,
  useIsEmployee,
  useIsEmployeeView,
  useUserID,
  useShouldApplyCustomerPermissions,
} from '../../hooks/id_token_claims'
import { DOPPEL_INTERNAL_PURPLE, DOPPEL_WHITE } from '../../utils/style'
import { IoMdCopy } from 'react-icons/io'

/**
 * EmployeeViewToggle is a React component that provides a toggle button
 * to switch between Employee View and Customer View, as well as to toggle
 * the application of customer permissions.
 *
 * @returns {JSX.Element} The rendered EmployeeViewToggle component.
 */
export default function InternalDropdown() {
  const [isEmployeeView, setIsEmployeeView] = useIsEmployeeView()
  const [shouldApplyCustomerPermissions, setShouldApplyCustomerPermissions] =
    useShouldApplyCustomerPermissions()
  const [orgData] = getOrgData()
  const [userId] = useUserID()
  const { value, onCopy: onCopyUserId, hasCopied } = useClipboard(userId)
  const {
    value: orgDataValue,
    onCopy: onCopyOrgId,
    hasCopied: hasCopiedUserId,
  } = useClipboard(orgData.id)
  if (!useIsEmployee()) {
    return <></>
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        bgColor={DOPPEL_INTERNAL_PURPLE}
        size="xs"
        textColor={DOPPEL_WHITE}
      >
        <FaBug />
      </MenuButton>

      <MenuList>
        <MenuItem onClick={() => setIsEmployeeView(!isEmployeeView)} width="100%">
          <Flex alignItems="center" width="100%">
            <FaEye />

            <Text ml="2" size="xs">
              Show Customer View
            </Text>

            <Spacer />

            {!isEmployeeView ? <FaCheck /> : null}
          </Flex>
        </MenuItem>

        <MenuItem
          onClick={() =>
            setShouldApplyCustomerPermissions(!shouldApplyCustomerPermissions)
          }
          width="100%"
        >
          <Flex alignItems="center" width="100%">
            <FaLock />

            <Text ml="2" size="xs">
              Apply Customer Permissions (eng)
            </Text>

            <Spacer />

            {shouldApplyCustomerPermissions ? <FaCheck /> : null}
          </Flex>
        </MenuItem>

        <MenuItem onClick={onCopyOrgId} width="100%">
          <Flex alignItems="center" width="100%">
            <IoMdCopy />

            <Text ml="2" size="xs">
              Copy Current Org Id
            </Text>

            <Spacer />
          </Flex>
        </MenuItem>

        <MenuItem onClick={onCopyUserId} width="100%">
          <Flex alignItems="center" width="100%">
            <IoMdCopy />

            <Text ml="2" size="xs">
              Copy Your User Id
            </Text>

            <Spacer />
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
