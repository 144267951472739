import {
  MenuItem,
  Input,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Flex,
  Spacer,
  MenuOptionGroup,
  Box,
  IconButton,
  Divider,
  Checkbox,
} from '@chakra-ui/react'
import React, { useState, useMemo } from 'react'
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons'
import {
  DOPPEL_CYBER_BLUE,
  DOPPEL_CYBER_BLUE_SHADE,
  DOPPEL_DARK_CLICKABLE,
  DOPPEL_DARK_CLICKABLE_HOVER,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_INTERNAL_PURPLE_SHADE,
  MENU_LIST_ZINDEX,
} from '@/utils/style'

type Props = {
  items: string[]
  selectedItems: string[]
  setSelectedItems: (selectedItems: string[]) => void
  isMultiSelect: boolean
  buttonDisplayFunction: (selectedItems: string[]) => string | JSX.Element // handles both cases with 0 and >0 items selected
  itemDisplayFunction?: (item: string) => string
  disableClearSelection?: boolean // if true, even the x won't appear
  clearSelectionOptionText?: string | JSX.Element // if null, option won't appear in menu
  showSearchBar?: boolean
  placeholder: string // for the search bar
  allowQueryAsCustomOption?: boolean // only for single-select; allows adding custom options not in the list
  icon?: JSX.Element
  isInternal?: boolean
  w?: string
  customButton?: JSX.Element
  listWidth?: string
  disabled?: boolean
}

const OPTION_FONT_SIZE = 13

export default function DropdownMenu({
  items,
  selectedItems: rawSelectedItems,
  setSelectedItems,
  isMultiSelect,
  buttonDisplayFunction,
  itemDisplayFunction = (item) => item,
  disableClearSelection = false,
  clearSelectionOptionText = null,
  showSearchBar = false,
  placeholder = null,
  allowQueryAsCustomOption = false,
  icon = <ChevronDownIcon />,
  isInternal = false,
  w = '155px',
  customButton = null,
  listWidth = null,
  disabled = false,
}: Props) {
  const [searchQuery, setSearchQuery] = useState('')

  const selectedItems = rawSelectedItems.filter((item) => item)

  const filteredItems = useMemo(() => {
    return items.filter((item) =>
      itemDisplayFunction(item).toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }, [items, searchQuery])

  const isAnyItemSelected = selectedItems.length > 0

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) =>
    setSearchQuery(event.target.value)

  const handleItemSelect = (item: string) => {
    let newItems: string[]
    if (selectedItems.includes(item)) {
      // unselect if the item is already selected
      newItems = selectedItems.filter((x) => x !== item)
    } else {
      // otherwise replace or append, depending on if it's multi-select
      newItems = isMultiSelect ? [...selectedItems, item] : [item]
    }
    setSelectedItems(newItems)
  }

  const handleClearSelection = () => setSelectedItems([])

  const selectedItemsToOptionGroupValue = (selectedItems: string[]) => {
    return isMultiSelect ? selectedItems : selectedItems[0]
  }

  let buttonColors = {
    text: 'white',
    bg: DOPPEL_DARK_CLICKABLE,
    bgHover: DOPPEL_DARK_CLICKABLE_HOVER,
  }
  if (isAnyItemSelected) {
    buttonColors = {
      text: 'white',
      bg: DOPPEL_CYBER_BLUE,
      bgHover: DOPPEL_CYBER_BLUE_SHADE,
    }
  } else if (isInternal) {
    buttonColors = {
      text: 'white',
      bg: DOPPEL_INTERNAL_PURPLE,
      bgHover: DOPPEL_INTERNAL_PURPLE_SHADE,
    }
  }

  const dropdownButton = () => {
    return customButton ? (
      customButton
    ) : (
      <MenuButton
        _active={{
          backgroundColor: buttonColors.bgHover,
        }}
        _hover={{
          backgroundColor: buttonColors.bgHover,
        }}
        as={Button}
        bgColor={buttonColors.bg}
        color={buttonColors.text}
        disabled={disabled}
        fontSize={12}
        h="30px"
        leftIcon={icon}
        rightIcon={
          !disableClearSelection &&
          isAnyItemSelected && (
            <IconButton
              aria-label="Clear selection"
              as={Box}
              h="16px"
              icon={<CloseIcon h="8px" w="8px" />}
              isRound={true}
              onClickCapture={handleClearSelection}
              size="16px"
              w="16px"
            />
          )
        }
        size="sm"
        w="100%"
      >
        <Flex h="18" w="100%">
          <Spacer />

          <Flex alignItems="center" h="100%" justifyContent="center">
            {buttonDisplayFunction(selectedItems)}
          </Flex>

          <Spacer />
        </Flex>
      </MenuButton>
    )
  }

  return (
    <Flex w={w}>
      <Menu closeOnSelect={!isMultiSelect}>
        {dropdownButton()}

        <MenuList
          minWidth={listWidth}
          onFocus={(e) => {
            // workaround for MenuList stealing focus from input
            // since our usage of the Chakra UI Menu component is non-standard
            if (e.relatedTarget?.className?.includes('chakra-input')) {
              ;(e.relatedTarget as HTMLElement).focus()
            }
          }}
          zIndex={MENU_LIST_ZINDEX}
        >
          {showSearchBar && (
            <Input
              fontSize={OPTION_FONT_SIZE}
              mx={2}
              my={1}
              onChange={handleSearchChange}
              placeholder={placeholder}
              size="sm"
              variant="filled"
              width="90%"
            />
          )}

          {!disableClearSelection && clearSelectionOptionText && !searchQuery ? (
            <MenuItem
              closeOnSelect={true}
              fontSize={OPTION_FONT_SIZE}
              onClick={handleClearSelection}
            >
              {clearSelectionOptionText}
            </MenuItem>
          ) : null}

          <Box maxH="240px" overflowX="auto" overflowY="auto">
            <MenuOptionGroup
              type={isMultiSelect ? 'checkbox' : 'radio'}
              value={selectedItemsToOptionGroupValue(selectedItems)}
            >
              {filteredItems.map((item) => (
                <MenuItem
                  fontSize={OPTION_FONT_SIZE}
                  icon={
                    isMultiSelect ? (
                      <Checkbox
                        isChecked={selectedItems.includes(item)}
                        pointerEvents="none"
                      />
                    ) : null
                  }
                  key={item}
                  onClick={() => handleItemSelect(item)}
                  value={item}
                >
                  {itemDisplayFunction(item)}
                </MenuItem>
              ))}
            </MenuOptionGroup>

            {allowQueryAsCustomOption &&
              !isMultiSelect && // custom option is not supported for multi-select
              searchQuery && // custom option requires search bar enabled
              !filteredItems.includes(searchQuery) && (
                <>
                  <Divider my={2} />

                  <MenuItem
                    fontSize={OPTION_FONT_SIZE}
                    onClick={() => handleItemSelect(searchQuery)}
                  >
                    Custom &quot;{searchQuery}&quot;
                  </MenuItem>
                </>
              )}
          </Box>
        </MenuList>
      </Menu>
    </Flex>
  )
}
