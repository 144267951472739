// Represents a single filter option in an overall filtering context.
//  (e.g. reports table filters, AI prompt filters, enforcements platform filters, etc.)
// These are used both for the lists of filter options per dropdown, and for the selected filters themselves.
export type BaseFilter<FilterType> = {
  filterType: FilterType
  value: string
}

export function getFilterValues<FilterType>(filterOptions: BaseFilter<FilterType>[]) {
  return filterOptions.map((filterOption) => filterOption.value)
}

export function upsertFilterListForType<FilterType>(
  filterType: FilterType,
  values: string[],
  reportFilters: BaseFilter<FilterType>[],
): BaseFilter<FilterType>[] {
  const updatedReportFilters = reportFilters.filter(
    (filter: BaseFilter<FilterType>) => filter.filterType != filterType,
  )
  values.forEach((value) => {
    updatedReportFilters.push({ filterType: filterType, value: value })
  })
  return updatedReportFilters
}
