import { DOPPEL_BREACH_RED, DOPPEL_SECURE } from '../../utils/style'
import { Flex, IconButton, Spinner, Tag, WrapItem } from '@chakra-ui/react'
import { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'

export function DoppelTag({
  tag,
  loading,
  bgColor,
  color,
  width,
  height,
  handleDelete,
}: {
  tag: string
  loading?: boolean
  bgColor?: string
  color?: string
  width?: string
  height?: string
  handleDelete?: () => void
}) {
  const [showDeleteTagButton, setShowDeleteTagButton] = useState('')

  return (
    <Flex
      key={tag}
      onMouseEnter={() => setShowDeleteTagButton(tag)}
      onMouseLeave={() => setShowDeleteTagButton('')}
      style={{
        width: 'fit-content',
        marginRight: '3px',
      }}
    >
      <WrapItem>
        <Tag
          fontSize={12}
          style={{
            ...(width && { width: width }),
            ...(height && { height: height }),
            ...(bgColor && { backgroundColor: bgColor }),
            ...(color && { color: color }),
          }}
        >
          {loading ? <Spinner size="xs" /> : tag}
        </Tag>
      </WrapItem>

      {handleDelete && showDeleteTagButton === tag && (
        <IconButton
          _hover={{ backgroundColor: DOPPEL_BREACH_RED }}
          aria-label="Remove Tag"
          icon={<AiOutlineClose />}
          onClick={() => handleDelete()}
          style={{
            height: '100%',
            width: 'fit-content',
            marginLeft: '2px',
          }}
        />
      )}
    </Flex>
  )
}
