import { useRouter } from 'next/router'
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { getOrgData, useIsEmployeeView, useUserID } from '../hooks/id_token_claims'
import {
  OrganizationType,
  ReportFilterType,
  ReportStatus,
  ReportType,
} from '../utils/constants'
import {
  getReportTypeFromPath,
  REPORT_TYPE_TO_PATH,
} from '../utils/reports/report_utils'
import PageLayout, { TabInfo } from './layout/page_layout'
import {
  useGetFilterablePlatformsByProductQuery,
  useGetUserByIdQuery,
} from '../generated/graphql'
import {
  getReportStatuses,
  getProductsByReportType,
} from '../utils/reports/report_utils'
import { defaultSortingMechanism } from '../utils/reports/report_sorting_mechanism'
import * as Sentry from '@sentry/nextjs'
import {
  getAllFiltersByType,
  getFilterValue,
  removeFilterByType,
} from '@/hooks/report_table_filters'
import { parseQueryParams } from '@/utils/reports/query_params'

const ReportContext = createContext(null)

export function useDefaultReportStatus() {
  const [userId] = useUserID()
  const { data: userInfo } = useGetUserByIdQuery({
    variables: { id: userId },
  })
  const [isEmployeeView] = useIsEmployeeView()

  const defaultStatus = isEmployeeView
    ? ReportStatus.ENUM_NEEDS_REVIEW
    : ReportStatus.ENUM_NEEDS_CONFIRMATION
  const [defaultReportStatus, setDefaultReportStatus] = useState(
    userInfo?.users?.[0]?.app_settings?.default_report_status,
  )

  useEffect(() => {
    setDefaultReportStatus(
      userInfo?.users?.[0]?.app_settings?.default_report_status ?? defaultStatus,
    )
  }, [userInfo, defaultStatus])

  return defaultReportStatus
}

export function useSelectedReportType() {
  const [isEmployeeView] = useIsEmployeeView()
  const defaultReportStatus = useDefaultReportStatus()

  const { type } = useContext(ReportContext)
  const [selectedReportType, setSelectedReportType] = type
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()
  const [, setSelectedSortingMechanism] = useSelectedSortingMechanism()
  const [, setSelectedReportStatus] = useSelectedReportStatus()

  const handleReportTypeChange = useCallback(
    (reportType) => {
      // Every time the report type changes, reset other state
      if (reportType !== selectedReportType) {
        const newReportStatus =
          reportType === ReportType.NFTS
            ? ReportStatus.NEEDS_REVIEW
            : defaultReportStatus

        setSelectedReportType(reportType)
        setSelectedReportStatus(newReportStatus)
        setSelectedSortingMechanism(
          defaultSortingMechanism(newReportStatus, reportType, isEmployeeView),
        )
        // reset all filters except organization, for all reports view
        const organizationFilters =
          getAllFiltersByType(selectedReportFilters)[ReportFilterType.Organization]
        setSelectedReportFilters(organizationFilters ?? [])
      }
    },
    [selectedReportType, selectedReportFilters, defaultReportStatus, isEmployeeView],
  )

  // We should eventually move away from having this look like a simple useState hook; since it's misleading
  return [selectedReportType, handleReportTypeChange]
}

export function useSelectedReportFilters() {
  const { selectedReportFilterList } = useContext(ReportContext)
  const [selectedReportFilters, setSelectedReportFilters] = selectedReportFilterList
  return [selectedReportFilters, setSelectedReportFilters]
}

export function useReportStatuses() {
  const { statuses } = useContext(ReportContext)
  const [reportStatuses, setReportStatuses] = statuses
  return [reportStatuses, setReportStatuses]
}

export function useSelectedReportStatus() {
  const { selectedStatus } = useContext(ReportContext)
  const [selectedReportStatus, setSelectedReportStatus] = selectedStatus
  return [selectedReportStatus, setSelectedReportStatus]
}

export function useSelectedSortingMechanism() {
  const { selectedSorting } = useContext(ReportContext)
  const [selectedSortingMechanism, setSelectedSortingMechanism] = selectedSorting
  return [selectedSortingMechanism, setSelectedSortingMechanism]
}

export function useShouldDisableArrowNavigation() {
  const { navigationDisabled } = useContext(ReportContext)
  const [shouldDisableArrowNavigation, setShouldDisableArrowNavigation] =
    navigationDisabled
  return [shouldDisableArrowNavigation, setShouldDisableArrowNavigation]
}

export function useSearchKey() {
  const { searchKey } = useContext(ReportContext)
  const [searchKeyValue, setSearchKeyValue] = searchKey
  return [searchKeyValue.toLowerCase(), setSearchKeyValue]
}

export function useSelectedAutocompleteReportId() {
  const { selectedAutocompleteReportId } = useContext(ReportContext)
  const [selectedAutocompleteReportIdValue, setSelectedAutocompleteReportId] =
    selectedAutocompleteReportId
  return [selectedAutocompleteReportIdValue, setSelectedAutocompleteReportId]
}

export function useClearReportSearch(): () => void {
  const { clearReportSearch } = useContext(ReportContext)
  return clearReportSearch
}

export function usePlatformsData() {
  const { platformsDataState } = useContext(ReportContext)
  const [platformsData, platformsDataLoading] = platformsDataState
  return [platformsData, platformsDataLoading]
}

export function useTabs() {
  const { tabs } = useContext(ReportContext)
  const [tabsValue, setTabsValue] = tabs
  return [tabsValue, setTabsValue]
}

const Reports = ({ component }) => {
  const router = useRouter()
  const [selectedReportType, handleReportTypeChange] = useSelectedReportType()
  const [tabsValue] = useTabs()

  useEffect(() => {
    // sets the report type if it's not already set
    // only used when no report type is in the URL (app.doppel.com/)
    if (tabsValue.length > 0 && !selectedReportType) {
      handleReportTypeChange(tabsValue[0].name)
    }
  }, [tabsValue])

  return (
    <PageLayout
      component={component}
      onTabChange={(tabInfo) => {
        handleReportTypeChange(tabInfo.name)
        // don't need to set the route; reports page takes care of it
        if (
          selectedReportType === ReportType.NFTS ||
          tabInfo.name === ReportType.NFTS
        ) {
          // unless switching to or from NFTs (different page)
          // in which case this clunkily navigates
          router.push(tabInfo.route)
        }
      }}
      tabs={tabsValue}
    />
  )
}

export default Reports

export const ReportsProvider: FC<{ children }> = ({ children }) => {
  const router = useRouter()
  const [orgData] = getOrgData()
  const [isEmployeeView] = useIsEmployeeView()

  const [tabsValue, setTabsValue] = useState<Array<TabInfo>>([])
  const [selectedReportType, setSelectedReportType] = useState<ReportType>(
    // query holds the dynamic route for /admin/reports/[report_type]
    getReportTypeFromPath(router) as ReportType,
  )
  const [selectedReportFilters, setSelectedReportFilters] = useState([])
  const [reportStatuses, setReportStatuses] = useState([])
  const [selectedReportStatus, setSelectedReportStatus] = useState(
    selectedReportType == ReportType.NFTS
      ? ReportStatus.NEEDS_REVIEW
      : ReportStatus.ENUM_NEEDS_REVIEW,
  )
  const [shouldDisableArrowNavigation, setShouldDisableArrowNavigation] =
    useState(false)

  const [searchKey, setSearchKey] = useState('')
  const [selectedAutocompleteReportId, setSelectedAutocompleteReportId] = useState('')
  const [selectedSortingMechanism, setSelectedSortingMechanism] = useState(
    defaultSortingMechanism(
      selectedReportStatus as ReportStatus,
      selectedReportType,
      isEmployeeView,
    ),
  )
  const defaultReportStatus = useDefaultReportStatus()

  const products = getProductsByReportType(selectedReportType)

  const { data: platformsResult, loading: platformsDataLoading } =
    useGetFilterablePlatformsByProductQuery({
      variables: {
        orgId: orgData?.id,
        products: products,
        reportStatuses: getReportStatuses(isEmployeeView),
      },
      skip: !orgData?.id || !selectedReportType,
    })

  const platformsData =
    platformsResult?.spoofing_reports?.map((platform) => platform.type) || []

  const setStateFromQueryParams = () => {
    // misses some cases which are handled elsewhere, such as default report type (first module) and search key

    const { reportFilters, reportStatus, sortingMechanism } = parseQueryParams(
      new URLSearchParams(window.location.search),
    )
    const reportType = getReportTypeFromPath(router) as ReportType
    if (reportType) setSelectedReportType(reportType)
    setSelectedReportFilters(reportFilters)
    setSelectedReportStatus(reportStatus ?? defaultReportStatus)
    if (sortingMechanism || reportType) {
      setSelectedSortingMechanism(
        sortingMechanism ??
          defaultSortingMechanism(
            (reportStatus ?? defaultReportStatus) as ReportStatus,
            reportType,
            isEmployeeView,
          ),
      )
    }
  }

  useEffect(() => {
    setStateFromQueryParams()
  }, [router.asPath])

  const clearReportSearch = () => {
    setSearchKey('')
    setSelectedAutocompleteReportId('')
    setSelectedReportFilters(
      removeFilterByType(ReportFilterType.Search, selectedReportFilters),
    )
  }

  const isFirstOrgDataLoad = useRef(true)

  useEffect(() => {
    if (orgData) {
      setTabsValue(getTabsForOrg(orgData))
      if (isFirstOrgDataLoad.current) {
        const paramSearchKey = getFilterValue(
          ReportFilterType.Search,
          selectedReportFilters,
        )
        if (paramSearchKey) setSearchKey(paramSearchKey)
        isFirstOrgDataLoad.current = false
      } else {
        clearReportSearch()
      }
    }
  }, [orgData])

  useEffect(() => {
    Sentry.setContext('report_context', {
      selectedReportStatus: selectedReportStatus,
      selectedReportType: selectedReportType,
    })
  }, [selectedReportStatus, selectedReportType, isEmployeeView])

  return (
    <ReportContext.Provider
      value={{
        type: [selectedReportType, setSelectedReportType],
        statuses: [reportStatuses, setReportStatuses],
        selectedStatus: [selectedReportStatus, setSelectedReportStatus],
        navigationDisabled: [
          shouldDisableArrowNavigation,
          setShouldDisableArrowNavigation,
        ],
        searchKey: [searchKey, setSearchKey],
        selectedAutocompleteReportId: [
          selectedAutocompleteReportId,
          setSelectedAutocompleteReportId,
        ],
        clearReportSearch: clearReportSearch,
        platformsDataState: [platformsData, platformsDataLoading],
        tabs: [tabsValue, setTabsValue],
        selectedReportFilterList: [selectedReportFilters, setSelectedReportFilters],
        selectedSorting: [selectedSortingMechanism, setSelectedSortingMechanism],
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}

export const getTabsForOrg = (orgData): Array<TabInfo> => {
  const isMarketplace = orgData.organization_type == OrganizationType.MARKETPLACE
  const shouldShowWeb2Tabs = orgData.is_web2_enabled && !isMarketplace

  // set order for tabs too
  const reportTypeToIsVisible: Partial<Record<ReportType, boolean>> = {
    [ReportType.DOMAINS]: shouldShowWeb2Tabs,
    [ReportType.SOCIAL_MEDIA]: orgData.is_social_media_detection_enabled,
    [ReportType.MOBILE_APPS]: orgData.is_app_detection_enabled,
    [ReportType.DARK_WEB]: orgData.is_dark_web_detection_enabled,
    [ReportType.DARK_MARKET]: orgData.is_dark_market_detection_enabled,
    [ReportType.ECOMMERCE]: orgData.is_ecommerce_detection_enabled,
    [ReportType.EMAIL]: orgData.is_email_detection_enabled,
    [ReportType.PAID_ADS]: orgData.is_paid_ads_detection_enabled,
    [ReportType.CRYPTO]: orgData.is_crypto_detection_enabled,
    [ReportType.METAVERSE]: [
      '1dff6f78-5a80-4cb7-a263-7a39f3cfbd18',
      '717b1cc4-62a7-4d88-8b73-1a5d4617bb05', // Sony Music
    ].includes(orgData.id),
    [ReportType.CODE_REPOS]: orgData.id === '46aec505-64a2-4304-bf93-dfae13f3e4ec',
    [ReportType.TELCO]: orgData.is_telco_detection_enabled,
    [ReportType.NFTS]: orgData.is_nft_detection_enabled,
  }
  return Object.entries(reportTypeToIsVisible)
    .filter(([, isVisible]) => isVisible)
    .map(([reportType, isVisible]) => ({
      name: reportType,
      route: `/${REPORT_TYPE_TO_PATH[reportType]}`,
      isVisible,
    })) as Array<TabInfo>
}
