import moment from 'moment-timezone'

export function getTimestampDisplay(
  timestamp: string,
  formatString = 'MMM Do, YYYY, h:mma',
): string {
  return moment.utc(timestamp).tz(moment.tz.guess()).format(formatString)
}

export function getPostgresDate(date: Date): string {
  return moment(date).format('YYYY-MM-DD')
}
