import React, { createContext, useContext, useState } from 'react'
import { SHOW_ALL_FILTER_VALUE } from '../components/tables/filter_dropdown'
import { Collection_Reports_Bool_Exp } from '../generated/graphql'
import {
  DISNEY_ORG_ID,
  DNS_PROVIDERS,
  ReportFilterType,
  ReportStatus,
  STANDARD_PAGE_SIZES,
} from '../utils/constants'
import { getPostgresDate } from '../utils/time'
const ReportTableContext = createContext(null)

// filters for the report table context; see base_filters.tsx for more info
export type ReportFilter = {
  filterType: ReportFilterType
  value: string
  imageUrl?: string
}

export const getAllFiltersByType = (
  reportFilters: ReportFilter[],
): { [filterType: string]: ReportFilter[] } => {
  return reportFilters.reduce((acc, filter) => {
    if (filter.value == 'Show All') return // ignore any 'Show All' values
    if (!acc[filter.filterType]) {
      acc[filter.filterType] = []
    }
    acc[filter.filterType].push(filter)
    return acc
  }, {})
}

export const FILTER_VALUE_TO_DISPLAY = {
  'Show All': SHOW_ALL_FILTER_VALUE,
  last_30_days: 'Last 30 Days',
  last_7_days: 'Last 7 Days',
  last_1_day: 'Last 24 hours',
}

export const MATCH_TYPE_FILTER_VALUE_TO_DISPLAY = {
  'Match Type': 'Match Type',
  Image: 'Image',
  Keyword: 'Keyword',
  IP: 'Logo',
}

export function extractSearchKey(reportFilters: ReportFilter[]): string {
  const searchFilter: ReportFilter = reportFilters.find(
    (reportFilter: ReportFilter) => reportFilter.filterType == ReportFilterType.Search,
  )
  if (searchFilter == null) {
    return ''
  }
  return searchFilter.value
}

// many of these helpers, if not report specific, can be moved into base_filters.tsx if needed

export function addFilter(
  type: ReportFilterType,
  value: string,
  reportFilters: ReportFilter[],
): ReportFilter[] {
  const reportFiltersCopy = [...reportFilters]
  reportFiltersCopy.push({ filterType: type, value: value })
  return reportFiltersCopy
}

export function genReportFilter(
  filterType: ReportFilterType,
  value: string,
  imageUrl?: string,
): ReportFilter {
  return {
    filterType: filterType,
    value: value,
    imageUrl: imageUrl,
  }
}

export function upsertFilter(
  reportFilter: ReportFilter,
  reportFilters: ReportFilter[],
): ReportFilter[] {
  const updatedReportFilters = reportFilters.filter(
    (filter: ReportFilter) => filter.filterType != reportFilter.filterType,
  )
  updatedReportFilters.push(reportFilter)
  return [...updatedReportFilters]
}

export function containsFilterByType(
  type: ReportFilterType,
  reportFilters: ReportFilter[],
): boolean {
  return reportFilters.some((filter: ReportFilter) => filter.filterType == type)
}

export function containsFilter(
  type: ReportFilterType,
  value: string,
  reportFilters: ReportFilter[],
): boolean {
  return reportFilters.some(
    (filter: ReportFilter) => filter.filterType == type && filter.value == value,
  )
}

export function getFilterValue(
  type: ReportFilterType,
  reportFilters: ReportFilter[],
): string {
  const filter = reportFilters.find((filter: ReportFilter) => filter.filterType == type)
  return filter == undefined ? undefined : filter.value
}

export function removeFilter(
  type: ReportFilterType,
  value: string,
  reportFilters: ReportFilter[],
): ReportFilter[] {
  const updatedReportFilters = reportFilters.filter(
    (filter: ReportFilter) => filter.filterType != type || filter.value != value,
  )
  return updatedReportFilters
}

export function removeFilterByType(
  type: ReportFilterType,
  reportFilters: ReportFilter[],
): ReportFilter[] {
  const updatedReportFilters = reportFilters.filter(
    (filter: ReportFilter) => filter.filterType != type,
  )
  return updatedReportFilters
}

export function collectionReportsWhereClause(
  reportFilters,
  organizationID,
  statuses: ReportStatus[],
  ownerID,
  orgData,
  collectionReportType,
): Collection_Reports_Bool_Exp {
  if (statuses.length == 0) {
    statuses.push(ReportStatus.NEEDS_REVIEW) // TODO set this in reports.tsx once enums have been consolidated
  }
  const whereClauses: any[] = [
    { organization_id: { _eq: organizationID } },
    { status: { _in: statuses } }, // TODO set this in reports.tsx once enums have been consolidated
    { report_type: { _eq: collectionReportType } },
  ]

  // add report assignment filters if needed
  reportFilters.forEach((filter) => {
    if (filter.value == 'Show All') return
    switch (filter.filterType) {
      case ReportFilterType.ReportOwner:
        if (filter.value == 'me') {
          whereClauses.push({ owner_id: { _eq: ownerID } })
        }
        if (orgData.users) {
          const user = orgData.users.find((user) => user.name == filter.value)
          if (user) {
            whereClauses.push({ owner_id: { _eq: user.id } })
          }
        }
        break
      case ReportFilterType.DateRange: {
        const dateRange = filter.value
          .split('#')
          .map((dateString) => new Date(dateString))

        whereClauses.push({
          created_at: {
            _lte: getPostgresDate(dateRange[1]),
            _gte: getPostgresDate(dateRange[0]),
          },
        })
        break
      }
      case ReportFilterType.Chain:
        whereClauses.push({
          flagged_collection: { chain: { _eq: filter.value } },
        })
        break
      case ReportFilterType.Collection:
      case ReportFilterType.Brand:
        whereClauses.push({
          original_collection: { collection_slug: { _in: filter.value } },
        })
        break
      case ReportFilterType.MatchType:
        if (organizationID == DISNEY_ORG_ID) {
          if (filter.value == 'Image' || filter.value == 'Keyword')
            whereClauses.push({
              match_types: { _has_key: filter.value.toLowerCase() },
            })
          if (filter.value == 'IP')
            whereClauses.push({
              match_types: { _has_key: 'logo' },
            })
        } else {
          whereClauses.push(getMatchTypeFilterByMatches(filter))
        }
        break
      case ReportFilterType.Tag:
        if (filter.value == 'DNS Providers')
          whereClauses.push({
            flagged_collection: { id: { _in: DNS_PROVIDERS } },
          })
        break
    }
  })

  return {
    _and: whereClauses,
  }
}

/**
 * @deprecated
 * @returns
 */
function getMatchTypeFilterByMatches(filter) {
  if (filter.value == 'Image')
    return {
      collection_reports_to_matches: {
        match: {
          _or: [
            {
              asset: {
                type: {
                  _in: [
                    'nft',
                    'collection_banner',
                    'collection_image',
                    'collection_profile',
                    'customer_upload',
                  ],
                },
              },
            },
            {
              image_score: {
                _is_null: false,
              },
            },
          ],
        },
      },
    }
  if (filter.value == 'Keyword')
    return {
      collection_reports_to_matches: {
        match: {
          _or: [
            {
              asset: { type: { _eq: 'keywords' } },
            },
            {
              keywords_score: {
                _is_null: false,
              },
            },
          ],
        },
      },
    }
  if (filter.value == 'IP')
    return {
      collection_reports_to_matches: {
        match: {
          _or: [
            {
              asset: { type: { _eq: 'logo' } },
            },
            {
              logo_score: {
                _is_null: false,
              },
            },
          ],
        },
      },
    }
}

export function useSelectedPage() {
  const { page } = useContext(ReportTableContext)
  const [selectedPage, setSelectedPage] = page
  return [selectedPage, setSelectedPage]
}

export function useSelectedPageSize() {
  const { pageSize } = useContext(ReportTableContext)
  const [selectedPageSize, setSelectedPageSize] = pageSize
  return [selectedPageSize, setSelectedPageSize]
}

export function useSelectedReport() {
  const { report } = useContext(ReportTableContext)
  const [selectedReport, setSelectedReport] = report
  return [selectedReport, setSelectedReport]
}

export function useNextSelectedReport() {
  const { nextReport } = useContext(ReportTableContext)
  const [nextSelectedReport, setNextSelectedReport] = nextReport
  return [nextSelectedReport, setNextSelectedReport]
}

export function useIsLoadingNextPage() {
  const { isLoadingNextPage } = useContext(ReportTableContext)
  const [isLoadingNextPageValue, setIsLoadingNextPageValue] = isLoadingNextPage
  return [isLoadingNextPageValue, setIsLoadingNextPageValue]
}

export function useIsLoadingPrevPage() {
  const { isLoadingPrevPage } = useContext(ReportTableContext)
  const [isLoadingPrevPageValue, setIsLoadingPrevPageValue] = isLoadingPrevPage
  return [isLoadingPrevPageValue, setIsLoadingPrevPageValue]
}

export const ReportTableFilterProvider: React.FC<{ children }> = ({ children }) => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [selectedPageSize, setSelectedPageSize] = useState(STANDARD_PAGE_SIZES[0])
  const [selectedReportFilters, setSelectedReportFilters] = useState({})

  const [selectedReport, setSelectedReport] = useState()
  const [nextSelectedReport, setNextSelectedReport] = useState()

  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false)
  const [isLoadingPrevPage, setIsLoadingPrevPage] = useState(false)

  return (
    <ReportTableContext.Provider
      value={{
        report: [selectedReport, setSelectedReport],
        nextReport: [nextSelectedReport, setNextSelectedReport],
        page: [selectedPage, setSelectedPage],
        pageSize: [selectedPageSize, setSelectedPageSize],
        reportFilters: [selectedReportFilters, setSelectedReportFilters],
        isLoadingNextPage: [isLoadingNextPage, setIsLoadingNextPage],
        isLoadingPrevPage: [isLoadingPrevPage, setIsLoadingPrevPage],
      }}
    >
      {children}
    </ReportTableContext.Provider>
  )
}
