import { Heading, Spacer, VStack, Image, Text, HStack, Link } from '@chakra-ui/react'
import LoginButton from '../components/login_button'
import { DOPPEL_OFFICIAL_BLUE } from '../utils/style'

const Login = () => {
  return (
    <VStack bg={DOPPEL_OFFICIAL_BLUE} minH="100vh" paddingBottom={10}>
      <Spacer />

      <VStack spacing="50px">
        <Image
          alt=""
          h="200px"
          src="https://storage.googleapis.com/doppel_brand_assets/Doppel_Avatar_Square.png"
        />

        <VStack spacing="20px">
          <Heading color="#FFFFFF" fontSize="74px">
            Doppel Vision
          </Heading>

          <Text color="#FFFFFF" fontSize="24px">
            Modern digital risk protection.
          </Text>
        </VStack>

        <LoginButton />
      </VStack>

      <Spacer />

      <HStack>
        <Text color="#FFFFFF" fontSize="14px">
          © {new Date().getFullYear()} Doppel
        </Text>

        <Link
          color="#FFFFFF"
          fontSize="14px"
          href="https://app.termly.io/document/privacy-policy/194662e6-a835-4542-abe4-942999ce5147"
          isExternal
        >
          Privacy Policy
        </Link>

        <Link
          color="#FFFFFF"
          fontSize="14px"
          href="https://app.termly.io/document/terms-of-use-for-saas/be0df5c9-83a7-494f-b17f-6926447a30b3"
          isExternal
        >
          Terms of Service
        </Link>
      </HStack>
    </VStack>
  )
}

export default Login
