import { Flex, Text } from '@chakra-ui/react'
import {
  BaseFilter,
  getFilterValues,
  upsertFilterListForType,
} from '@/hooks/base_filters'
import { useSelectedReportFilters } from '../../pages/reports'
import DropdownMenu from '../shared/forms/dropdown_menu'
import { ReportFilterType } from '@/utils/constants'
import { getTruncatedText } from '@/utils/string_utils'

export const SHOW_ALL_FILTER_VALUE = 'Show All'
const MAX_DISPLAY_LENGTH = 10

export const useSelectedContext = (context) => {
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()

  if (context) {
    return context
  }

  return [selectedReportFilters, setSelectedReportFilters]
}

type Props<FilterType> = {
  filterType: FilterType
  dropdownIcon: JSX.Element
  filterOptions: BaseFilter<FilterType>[]
  alphabetizeFilterOptions?: boolean
  valueToDisplayFunction?: (value: string) => string
  textMaxWidth?: string
  w?: string
  isMultiSelect?: boolean
  showSearchBar?: boolean
  placeholder?: string // for search bar, if enabled
  isRequired?: boolean // only for single select; removes ability to clear
  disableNoneSelected?: boolean
  context?: [BaseFilter<FilterType>[], (filters: BaseFilter<FilterType>[]) => void]
  isInternal?: boolean
  [key: string]: any
}

const FilterDropdown = <FilterType extends string>({
  filterType,
  dropdownIcon,
  filterOptions,
  alphabetizeFilterOptions = false,
  valueToDisplayFunction = (value) => {
    return value
  },
  textMaxWidth = null,
  isMultiSelect = false,
  showSearchBar = false,
  placeholder = null,
  isRequired = false,
  disableNoneSelected = !isMultiSelect,
  context = null,
  isInternal = false,
  maxDisplayLength = MAX_DISPLAY_LENGTH,
  disabled = false,
  w = '155px',
  ...props
}: Props<FilterType>) => {
  const [selectedFilters, setSelectedFilters] = useSelectedContext(context)

  const onSelectionChange = (values: string[]) => {
    // avoid interleaving issues by passing a function
    setSelectedFilters((prevFilters) =>
      upsertFilterListForType(filterType, values, prevFilters),
    )
  }

  if (!filterOptions) {
    return null
  }

  const displayFilterType = valueToDisplayFunction(filterType)

  const selectedFilterOptions = selectedFilters.filter(
    (filter: BaseFilter<FilterType>) => filter.filterType === filterType,
  )

  const buttonDisplayFunction = (selectedItems: any[]) => {
    let content: string
    if (selectedItems.length > 0) {
      if (isMultiSelect) {
        content = `${displayFilterType} (${selectedItems.length})`
      } else {
        content = valueToDisplayFunction(selectedItems[0])
      }
    } else {
      content = displayFilterType
    }
    content = getTruncatedText(content, maxDisplayLength)

    return (
      <Text fontSize={12} maxWidth={textMaxWidth} noOfLines={1}>
        {content}
      </Text>
    )
  }

  const filterValues = getFilterValues(filterOptions)
  if (alphabetizeFilterOptions) {
    filterValues.sort((a, b) => {
      a = valueToDisplayFunction(a)
      b = valueToDisplayFunction(b)
      return a.localeCompare(b, 'en', { sensitivity: 'base' })
    })
  }

  return (
    <Flex w={w} {...props}>
      <DropdownMenu
        buttonDisplayFunction={buttonDisplayFunction}
        clearSelectionOptionText={disableNoneSelected ? null : SHOW_ALL_FILTER_VALUE}
        disableClearSelection={isRequired}
        disabled={disabled}
        icon={dropdownIcon}
        isInternal={isInternal}
        isMultiSelect={isMultiSelect}
        itemDisplayFunction={(item) => valueToDisplayFunction(item)}
        items={filterValues}
        placeholder={placeholder ?? `Search for ${displayFilterType.toLowerCase()}...`}
        selectedItems={getFilterValues(selectedFilterOptions)}
        setSelectedItems={onSelectionChange}
        showSearchBar={showSearchBar}
        w={w}
      />
    </Flex>
  )
}

export const ReportFilterDropdown = FilterDropdown<ReportFilterType>
export default FilterDropdown
