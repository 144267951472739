import { extendTheme } from '@chakra-ui/react'
import { DOPPEL_BLACK, DOPPEL_DARK, DOPPEL_WHITE } from './utils/style'

// Define your color palette
const colors = {
  black: DOPPEL_BLACK,
  dark: {
    bg: DOPPEL_DARK, // Your new default color for dark mode
    text: DOPPEL_WHITE,
    // Add more custom colors as needed
  },
  light: {
    bg: '#F7FAFC', // Your new default color for light mode
    text: '#181921',
    // Add more custom colors as needed
  },
}

// Define your fonts
const fonts = {
  mono: `'Inter', monospace`,
  body: `'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
  heading: `'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
}

// Define your theme configuration
const config = {
  initialColorMode: 'dark', // Set the initial color mode to dark
  useSystemColorMode: false,
}

// Extend the default theme
const theme = extendTheme({
  colors,
  fonts,
  config,
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' ? 'dark.bg' : 'light.bg',
        color: props.colorMode === 'dark' ? 'dark.text' : 'light.text',
      },

      // highlighting
      em: {
        color: 'black',
      },

      // For the date range picker
      '.rdrStaticRangeLabel': {
        backgroundColor: 'inherit',
        color: DOPPEL_DARK,
      },
    }),
  },
})

export default theme
