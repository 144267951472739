import React, { createContext, useContext, useState } from 'react'
import { STANDARD_PAGE_SIZES } from '../utils/constants'

const EntitiesTableContext = createContext(null)

export function useSelectedPage() {
  const { page } = useContext(EntitiesTableContext)
  const [selectedPage, setSelectedPage] = page
  return [selectedPage, setSelectedPage]
}

export function useSelectedPageSize() {
  const { pageSize } = useContext(EntitiesTableContext)
  const [selectedPageSize, setSelectedPageSize] = pageSize
  return [selectedPageSize, setSelectedPageSize]
}

export function useSelectedEntity() {
  const { entities } = useContext(EntitiesTableContext)
  const [selectedEntity, setSelectedEntity] = entities
  return [selectedEntity, setSelectedEntity]
}

export function useSelectedProtectedAsset() {
  const { protectedAsset } = useContext(EntitiesTableContext)
  const [selectedProtectedAsset, setSelectedProtectedAsset] = protectedAsset
  return [selectedProtectedAsset, setSelectedProtectedAsset]
}

export const EntitiesTableProvider: React.FC<{ children }> = ({ children }) => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [selectedPageSize, setSelectedPageSize] = useState(STANDARD_PAGE_SIZES[0])
  const [selectedEntity, setSelectedEntity] = useState()
  const [selectedProtectedAsset, setSelectedProtectedAsset] = useState()

  return (
    <EntitiesTableContext.Provider
      value={{
        page: [selectedPage, setSelectedPage],
        pageSize: [selectedPageSize, setSelectedPageSize],
        entities: [selectedEntity, setSelectedEntity],
        protectedAsset: [selectedProtectedAsset, setSelectedProtectedAsset],
      }}
    >
      {children}
    </EntitiesTableContext.Provider>
  )
}
