export const DOPPEL_INTERNAL_PURPLE = '#806584'
export const DOPPEL_INTERNAL_PURPLE_SHADE = '#B78DB7'
export const DOPPEL_TERMINAL_BLUE = '#142349'
export const DOPPEL_TERMINAL_BLUE_SHADE = '#060D20'

export const DOPPEL_OFFICIAL_BLUE = '#0F52FF'

export const DOPPEL_CYBER_BLUE = '#336AEA'
export const DOPPEL_CYBER_BLUE_SHADE = '#3453B3'

export const DOPPEL_CLOUD_BLUE = '#3FAEFF'
export const DOPPEL_CLOUD_BLUE_SHADE = '#2F9EEE'

export const DOPPEL_WHITE = '#EEEFFB'
export const DOPPEL_LIGHT_GREY = '#B1B1B1'
export const DOPPEL_GREY = '#E7E7E7'
export const DOPPEL_BUTTON_GREY = '#4F5055'
export const DOPPEL_CLICKABLE_GREY = '#edf2f6'
export const DOPPEL_CLICKABLE_GREY_SHADE = '#DDE2E7'

export const DOPPEL_SECURE = '#727E9B'
export const DOPPEL_SECURE_SHADE = '#5C6989'

export const DOPPEL_BREACH_RED = '#DA615C'
export const DOPPEL_BREACH_RED_SHADE = '#E93C3C'

export const DOPPEL_FIREWALL_ORANGE = '#FFBE3F'
export const DOPPEL_FIREWALL_ORANGE_SHADE = '#ECAB2E'

export const DOPPEL_YELLOW_SHADE = '#F4C635'

export const DOPPEL_ENCRYPT_GREEN = '#229954'
export const DOPPEL_ENCRYPT_GREEN_SHADE = '#44EA73'

export const DOPPEL_BLACK = '#000000'
export const DOPPEL_DARK = '#181921'
export const DOPPEL_DARK_SECONDARY = '#20212D'
export const DOPPEL_DARK_CLICKABLE = '#272938'
export const DOPPEL_DARK_CLICKABLE_HOVER = '#2F3140'
export const DOPPEL_DARK_GREY = '#1E1E2A'

export const DELIST_COLOR = '#58FF87'
export const MENU_LIST_ZINDEX = 10
export const DOPPEL_SUCCESS = '#4d875d'
export const DOPPEL_ERROR = '#FF4E4E'
export const DOPPEL_LINK = '#93B3F2'
