import { useAuth0 } from '@auth0/auth0-react'
import { Button, Icon, Link, Text } from '@chakra-ui/react'
import { FiLogOut } from 'react-icons/fi'
import { getOrgData } from '../hooks/id_token_claims'
import { DOPPEL_GREY, DOPPEL_TERMINAL_BLUE } from '../utils/style'

const LogoutButton = ({ buttonSizeState, ...rest }) => {
  const { logout } = useAuth0()
  const [orgData] = getOrgData()

  return (
    <Link _focus={{ boxShadow: 'none' }} href="#" style={{ textDecoration: 'none' }}>
      <Button
        backgroundColor={DOPPEL_GREY}
        borderRadius="lg"
        color={DOPPEL_TERMINAL_BLUE}
        cursor="pointer"
        onClick={() => {
          localStorage.clear()
          let returnUrl = window.location.origin
          if (shouldForceRedirectLogout(orgData)) {
            returnUrl += '/custom-logout'
          }
          logout({
            federated: isFederated(orgData),
            returnTo: returnUrl,
          })
        }}
        p="4"
        role="group"
        transition="opacity"
        transitionDuration="0.4s"
        {...rest}
      >
        <Icon as={FiLogOut} fontSize="16" marginY="0.5" />

        {buttonSizeState != 'minimized' && (
          <Text fontSize="small" marginLeft="12px">
            {'Log Out'}
          </Text>
        )}
      </Button>
    </Link>
  )
}

const isFederated = (orgData): boolean => {
  return Boolean(
    orgData && orgData.auth_configs && orgData.auth_configs.needs_federated_saml_logout,
  )
}

const shouldForceRedirectLogout = (orgData): boolean => {
  return Boolean(
    orgData &&
      orgData.auth_configs &&
      orgData.auth_configs.should_force_redirect_logout &&
      orgData.auth_configs.idp_logout_url,
  )
}

export default LogoutButton
