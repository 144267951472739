import {
  VStack,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  Button,
  PopoverArrow,
  Text,
  FormControl,
  Textarea,
  Icon,
} from '@chakra-ui/react'
import { useState } from 'react'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { MdOutlineFeedback } from 'react-icons/md'
import {
  useSendSlackMessageMutation,
  useSubmitUserFeedbackMutation,
} from '../generated/graphql'
import { getOrgData, useEmail, useUserID } from '../hooks/id_token_claims'
import { PRODUCT_REQUESTS_CHANNEL_ID } from '../utils/constants'
import { DOPPEL_CYBER_BLUE } from '../utils/style'

export default function UserFeedbackComponent({
  promptLabel,
  selectedReport,
  iconSize = 18,
  ...props
}) {
  const [input, setInput] = useState('')
  const [userId] = useUserID()
  const [sendSlackMessageMutation] = useSendSlackMessageMutation()
  const [email] = useEmail()
  const [orgData] = getOrgData()
  const [submitUserFeedbackMutation, status] = useSubmitUserFeedbackMutation()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [shouldShowInputScreen, setShouldShowInputScreen] = useState(true)

  const handleChangeText = (e) => {
    setInput(e.target.value)
  }
  return (
    <Popover
      onClose={() => {
        setTimeout(
          function () {
            setHasSubmitted(false)
            setInput(null)
            setShouldShowInputScreen(true)
          }.bind(this),
          200, // wait for the close animation to finish
        )
      }}
      placement="top-start"
    >
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Button transition="width" transitionDuration="0.3s" {...props}>
              <Flex alignItems="center" justifyContent="start">
                <Flex paddingRight="2">
                  <MdOutlineFeedback size={iconSize} />
                </Flex>

                <Text fontSize={12}>{promptLabel}</Text>
              </Flex>
            </Button>
          </PopoverTrigger>

          <PopoverContent
            bg="blue.800"
            borderColor="blue.800"
            color="white"
            height="210px"
            width="350px"
          >
            <PopoverHeader border="0" fontWeight="bold" pt={2}>
              Send Us Feedback
            </PopoverHeader>

            <PopoverArrow bg="blue.800" />

            <PopoverCloseButton />

            <VStack>
              <VStack
                opacity={hasSubmitted ? 1 : 0}
                paddingTop="4"
                transition="opacity"
                transitionDelay="0.35s"
                transitionDuration="0.4s"
              >
                <Icon as={IoMdCheckmarkCircle} color={DOPPEL_CYBER_BLUE} h={8} w={8} />

                <Text marginRight="1" paddingBottom="4">
                  Thanks for your feedback!
                </Text>

                <Button color="gray.700" onClick={onClose}>
                  Close
                </Button>
              </VStack>

              {shouldShowInputScreen && (
                <VStack
                  onTransitionEnd={() => {
                    if (hasSubmitted) {
                      setShouldShowInputScreen(false)
                    }
                  }}
                  opacity={hasSubmitted ? 0 : 1}
                  padding="4"
                  position="absolute"
                  transition="opacity"
                  transitionDuration="0.4s"
                  width="100%"
                >
                  <FormControl>
                    <Textarea
                      onChange={handleChangeText}
                      placeholder={"I'm having an issue with..."}
                    />
                  </FormControl>

                  <Button
                    bgColor={DOPPEL_CYBER_BLUE}
                    color={'white'}
                    disabled={!input}
                    height="8"
                    onClick={() => {
                      submitUserFeedbackMutation({
                        variables: {
                          collection_report_id: selectedReport?.id,
                          text: input,
                          type: 'default',
                          user_id: userId,
                        },
                      })
                      sendSlackMessageMutation({
                        variables: {
                          slack_message_input: {
                            channel_id: PRODUCT_REQUESTS_CHANNEL_ID,
                            message: `[${orgData.name}] ${email} submitted feedback: "${input}"`,
                          },
                        },
                      })
                      setHasSubmitted(true)
                    }}
                    width="100%"
                  >
                    Send
                  </Button>
                </VStack>
              )}
            </VStack>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
