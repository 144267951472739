import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { useIsEmployee, useShouldApplyCustomerPermissions } from './id_token_claims'

const AuthorizedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently, isLoading } = useAuth0()
  const isEmployee = useIsEmployee()
  const [shouldApplyCustomerPermissions] = useShouldApplyCustomerPermissions()

  if (isLoading) {
    return <></>
  }

  const authLink = setContext(async () => {
    const token = await getAccessTokenSilently()
    return {
      headers: {
        'x-hasura-role':
          !shouldApplyCustomerPermissions && isEmployee ? 'admin' : 'user',
        Authorization: `Bearer ${token}`,
      },
    }
  })

  const httpLink = createHttpLink({
    uri: 'https://' + process.env.NEXT_PUBLIC_HASURA_API_URI,
  })

  const wsLink =
    typeof window === 'undefined'
      ? null
      : new GraphQLWsLink(
          createClient({
            url: 'wss://' + process.env.NEXT_PUBLIC_HASURA_API_URI,
            connectionParams: async () => {
              const token = await getAccessTokenSilently()
              return {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            },
          }),
        )

  const splitLink = wsLink
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query)
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          )
        },
        wsLink,
        httpLink,
      )
    : null

  const link = wsLink ? authLink.concat(splitLink) : authLink.concat(httpLink)

  const apolloClient = new ApolloClient({
    // connectToDevTools: true, enable to see in console in prod; can see in devtools by for localhost
    link: link,
    cache: new InMemoryCache({
      dataIdFromObject(responseObject) {
        if (
          !defaultDataIdFromObject(responseObject) &&
          !(
            responseObject.__typename.endsWith('aggregate') ||
            responseObject.__typename.endsWith('aggregate_fields')
          )
        ) {
          // Check for types that require a custom cache key
          // console.warn(`Type ${responseObject.__typename} doesn't have an \`id\` or \`_id\` field; object: ${JSON.stringify(responseObject)}`)
        }
        switch (responseObject.__typename) {
          // Add custom ids here; or with a `typePolicy`
          default:
            return defaultDataIdFromObject(responseObject)
        }
      },
      typePolicies: {
        platform: {
          keyFields: ['name'],
        },
        platforms: {
          keyFields: ['name'],
        },
        delisted_nfts: {
          keyFields: ['nft_id'],
        },
      },
    }),
  })
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
