export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID ?? ''

export const pageview = (url: URL) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  })
}

export const event = (
  action: Gtag.EventNames | string,
  customParams: Gtag.CustomParams,
) => {
  window.gtag('event', action, customParams)
}

export const setUser = (userID: string, orgId: string) => {
  window.gtag('set', {
    user_id: userID,
  })
  window.gtag('set', 'user_properties', {
    crm_id: userID,
    org_id: orgId,
  })
}
